
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Button } from 'components/common/Button';
import { BaseLayout } from 'layouts/BaseLayout';
import { GetStaticProps } from 'next';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import Image from 'next/image';
import { ReactElement } from 'react';
const NotFoundPage = () => {
    const { t } = useTranslation('404');
    return (<>
      <Head>
        <title>{t('page_not_found')}</title>
      </Head>
      <main className='flex h-full min-h-screen flex-1 flex-col items-center px-10 py-12 text-center xl:py-[60px]'>
        <div className='mb-8'>
          <Image src='/files/404.png' alt='404' width={579} height={250} quality={100} priority/>
        </div>
        <h1 className='text-2xl font-bold tracking-normal md:text-3xl'>
          {t('oops')}
        </h1>
        <h2 className='text-2xl font-bold tracking-normal md:text-3xl'>
          {t('page_not_found')}
        </h2>
        <h3 className='mt-5 text-sm font-normal tracking-normal text-cool-600 md:text-lg'>
          {t('this_page_doesnt_exist')}
        </h3>
        <h3 className='mb-8 text-sm font-normal tracking-normal text-cool-600 md:text-lg'>
          {t('we_suggest')}
        </h3>
        <Button type='link' href='/' size='lg'>
          {t('back_to_home')}
        </Button>
      </main>
    </>);
};
NotFoundPage.getLayout = (page: ReactElement) => {
    return <BaseLayout>{page}</BaseLayout>;
};
const getStaticProps: GetStaticProps = async () => {
    return { props: {} };
};
export default NotFoundPage;

    async function __Next_Translate__getStaticProps__19504d70a5e__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19504d70a5e__ as getStaticProps }
  